<template>
	<div>
		<!-- 商家绑定银行卡 -->
		<div class="queryBox mb20">
			<el-input size="mini" placeholder="请输入商家号" v-model="business_no" clearable class="mr20" style="width: 200px"></el-input>
			<el-input size="mini" placeholder="请输入商家名" v-model="business_name" clearable class="mr20" style="width: 200px"></el-input>
			<el-button size="mini" type="primary" :icon="disLoading?'el-icon-loading':'el-icon-search'" @click="getData" :disabled="disLoading">搜索</el-button>
		</div>
		<el-table :data="tableData" :max-height="height" border stripe v-loading="loading"  size="mini" style="width: 80%;">
			<el-table-column type="index" label="序号" align="center"></el-table-column>
			<el-table-column prop="business_name" label="商家" align="center"></el-table-column>
			<el-table-column  label="状态" align="center">
				<template slot-scope="scope">
					<span :class="scope.row.status== 0?'csuccess':'cdanger'">{{scope.row.status== 0?'开启':'关闭'}}</span>
				</template>
			</el-table-column>			
			<el-table-column prop="alipay_count" label="总账号数" align="center"></el-table-column>
			<!-- <el-table-column prop="name" label="普通卡数"></el-table-column> -->
			<!-- <el-table-column prop="name" label="公司卡数"></el-table-column> -->
			<el-table-column label="所有卡余额" align="center">
				<template slot-scope="scope">
					{{scope.row.total_true_money | tofixed}}
				</template>
			</el-table-column>
			<!-- <el-table-column label="普通卡余额">
				<template slot-scope="scope">
					{{scope.row.amount | tofixed}}
				</template>
			</el-table-column> -->
			<!-- <el-table-column label="公司卡余额">
				<template slot-scope="scope">
					{{scope.row.amount | tofixed}}
				</template>
			</el-table-column> -->
			<el-table-column label="所有卡业务余额" align="center">
				<template slot-scope="scope">
					{{scope.row.total_sys_money | tofixed}}
				</template>
			</el-table-column>
			<el-table-column label="今日充" align="center">
				<template slot-scope="scope">
					{{scope.row.today_in | tofixed}}
				</template>
			</el-table-column>
			<el-table-column label="今日提" align="center">
				<template slot-scope="scope">
					{{scope.row.today_out | tofixed}}
				</template>
			</el-table-column>
			<!-- <el-table-column label="已借备用金额">
				<template slot-scope="scope">
					{{scope.row.amount | tofixed}}
				</template>
			</el-table-column> -->
			<el-table-column label="资金使用率" align="center">
				<template slot-scope="scope">
					<span class="cdanger">{{scope.row.ratio | tofixed}}%</span>					
				</template>
			</el-table-column>
			<el-table-column prop="" label="操作" align="center">
				<template slot-scope="scope">
					  <el-button size="mini" @click="gotopage(scope.row)">加卡</el-button>
					  
				</template>
			</el-table-column>
		</el-table>
		<div class="flexX  mt20 pageBox">
			<el-pagination :hide-on-single-page="true" @size-change="handleSizeChange" @current-change="handleCurrentChange"
			 :current-page="page" :page-sizes="pageSizesArr" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
			 :total="total"></el-pagination>
		</div>
	</div>
</template>

<script>
	import {
		isEmpty,
		isPositiveNumber,
		isMoney
	} from '@/util/validate'
	import {
		Message
	} from 'element-ui'
	export default {
		data() {
			return {
				loading: false,
				disLoading: false,
				tableData:[],
				business_no:'',
				business_name:'',
				pageSizesArr: [20, 50, 100, 200],
				pageSize: 20,
				total: 0,
				page: 1,
				height: 0,
			}
		},
		created() {
			this.traderCard()
			this.$nextTick(() => {
				let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
				let threeDirectoryBox = document.getElementsByClassName('threeDirectoryBox')[0].offsetHeight;
				let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
				let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
				let documentHeight = document.documentElement.clientHeight
				this.height = documentHeight - topNav - 20 - pageBox - 20 - queryBox - threeDirectoryBox - 40
			})
		},
		filters: {
			tofixed: function(value) {
				if (value || value == 0) {
					// return ((value * 1).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
					let val =Math.floor(((value * 1)*100).toPrecision(12))
					return ((val/100).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
				}
			}
		},
		methods:{
			//查看列表
			traderCard() {
				let data = {
					business_no:this.business_no,
					business_name:this.business_name,
					page:this.page,
					size:this.pageSize
				}
				// this.loading = true
				this.disLoading = true
				this.$api.alipaytradereECny(data).then(res => {
					this.loading = false
					this.disLoading = false
					if (res.status == 1) {
						this.tableData = res.data.data
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				}).catch(error => {
					this.loading = false
					this.disLoading = false
				})
			},
			//查询按钮
			getData(){
				this.page = 1
				this.traderCard()
			},
			gotopage(item){
				this.$router.push({path:`/eCnyTraterAdd`,query:{business_no:item.business_no,trader_id:item.id}})
			},
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.page = 1
				this.pageSize = val
				this.traderCard()
			},
			handleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.page = val
				this.traderCard()
			},
			
		},
		watch: {
			total(val, old) {
				this.$nextTick(() => {
					let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
					let threeDirectoryBox = document.getElementsByClassName('threeDirectoryBox')[0].offsetHeight;
					let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
					let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
					let documentHeight = document.documentElement.clientHeight
					this.height = documentHeight - topNav - 20 - pageBox - 20 - queryBox - threeDirectoryBox - 40
				})
			}
		},
	}
</script>

<style>
</style>
